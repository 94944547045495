import axios from 'axios';
import { AuthStateManager } from './authStateManager';

export const setupAxiosInterceptors = (onLogout) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        const errorData = error.response.data;
        if (errorData && errorData.code === 'AUTH_SESSION_EXPIRED') {
          // Clear any stored authentication information
          await AuthStateManager.cleanupAuth();
          
          // Trigger logout or redirect
          if (onLogout && typeof onLogout === 'function') {
            onLogout();
          } else {
            window.location.href = '/login';
          }
        }
      }
      return Promise.reject(error);
    }
  );
}; 