import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleAuthError } from '../utils/handleAuthError';
import { getQuestionMessagesApi } from '../config/ApiConfig';

const deleteQuestionSlice = createSlice({
  name: 'deleteQuestion',
  initialState: {
    loading: false,
    data: null,
    error: ''
  },
  reducers: {
    deleteQuestionRequest(state) {
      state.loading = true;
    },
    deleteQuestionSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.error = '';
    },
    deleteQuestionFailure(state, action) {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    }
  }
});

export const { 
  deleteQuestionRequest, 
  deleteQuestionSuccess, 
  deleteQuestionFailure 
} = deleteQuestionSlice.actions;

export const deleteQuestion = (conversationId) => async (dispatch) => {
  dispatch(deleteQuestionRequest());
  const token = localStorage.getItem('authToken');

  try {
    await axios.delete(
      `${getQuestionMessagesApi()}/${conversationId}`,
      { 
        headers: { 'Authorization': `Bearer ${token}` }
      }
    );
    dispatch(deleteQuestionSuccess());
    return true;
  } catch (error) {
    handleAuthError(error);
    dispatch(deleteQuestionFailure(error.message));
    return false;
  }
};

export default deleteQuestionSlice.reducer;